import React from 'react'
import Nav from '../components/nav/Nav'

function TestPage() {
  return (
    <div>
        <Nav />
        <h1> Test Page</h1>
    </div>
  )
}

export default TestPage